<template>
  <b-avatar
    :src="avatar"
    square
    variant="light-primary"
    size="lg"
  />
  <!-- <b-avataimg
    :src="avatar"
    height="48"
    rounded
    alt="Rounded image"
    class="d-inline-block mr-1 mb-1"
  /> -->
</template>

<script>
import {
  BAvatar,
} from 'bootstrap-vue'
import Request from '@/plugins/service/index'

export default {
  components: {
    BAvatar,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      avatar: '',
    }
  },
  watch: {
    value() {
      this.getAvatar()
    },
  },
  created() {
    this.getAvatar()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async getAvatar() {
      const siteID = JSON.parse(localStorage.getItem('siteID')).id
      const res = await Request.get(
        this.$http,
        `${process.env.VUE_APP_API_URL}/tiktok/v1/get_product_detail?site_id=${siteID}&product_id=${this.value.id}`,
      )
      if (res.status === 200) {
        if (res.data.status) {
          // eslint-disable-next-line prefer-destructuring
          this.avatar = res.data.data.images[0].thumb_url_list[0]
          this.value.category_id = res.data.data.category_list[res.data.data.category_list.length - 1].local_display_name
        }
      }
    },
  },
}
</script>
